.App {
  text-align: center;
}

.header {
  font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", sans-serif;
  color: white;
  font-size: 4em;
  margin: 0;
}

.penguin {
  margin-top: 24vh;
}
 
.counter {
  width: 12vh!important;
  height: 12vh!important;
  background: #F1F1F1;
  border-style: inset;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 5vh;
  font-size: 1.5rem;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.imagef {
  height: calc(40vh*1.25);
  width: 40vh;
  border-radius: 10px;
}

@keyframes example {
  from   {top:-100px;}
  to {top: 100%;}
}

/* The element to apply the animation to */
.animation {
  width: 100px;
  height: 100px;
  position: absolute;
  animation: example linear infinite;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
